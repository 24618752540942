import { flattenDeep } from 'lodash';

import { toTitleCase } from '@ftf-old/util/Formatter';

export function processError(err) {
  if (!err) return 'Something went wrong';

  if (typeof err === 'string') {
    return err.trim();
  }

  if (err.response && err.response.data) {
    if (typeof err.response.data === 'string') {
      return `${err.response.status}: ${err.response.data.substr(0, 100)}...`;
    }

    const { errors } = err.response.data;

    if (Array.isArray(errors)) {
      const message = errors
        .map(error => {
          if (error.detail.includes(error.title)) {
            return toTitleCase(error.detail);
          }

          return `${toTitleCase(error.title)}. ${toTitleCase(error.detail)}`;
        })
        .join('; ');

      return message.trim();
    }

    if (typeof errors === 'string') {
      return errors.trim();
    }

    if (typeof errors === 'object') {
      let message = '';
      const keys = Object.keys(errors);

      for (let i = 0; i < keys.length; i += 1) {
        const error = keys[i];
        message = `${message} ${toTitleCase(error)}: ${errors[error]};`;
      }

      return message.trim();
    }
  }

  if (err.message) {
    return err.message;
  }

  return err.toString();
}

export function processAJAXError(jqXHR) {
  let message = '';

  try {
    const { error, errors } = jqXHR.responseJSON;

    if (error && typeof error === 'string') {
      message = error;
    }

    if (errors) {
      const errorMessages = Object.keys(errors).map(key => errors[key]);

      message = flattenDeep(errorMessages);
    }
  } catch (error) {
    message = 'Something went wrong';
  }

  return message;
}
