import { datadogRum } from '@datadog/browser-rum-slim';

import {
  currentEnv,
  datadogRumAppId,
  datadogRumClientToken,
  isTest,
  isDevelopment,
  reportingReleaseVersion,
} from '@ftf/config/env';

datadogRum.setUser({ id: window.__UID__ || 'NOT SET' });
datadogRum.init({
  applicationId: datadogRumAppId,
  clientToken: datadogRumClientToken,
  site: 'datadoghq.com',
  service: 'fund-that-flip',
  env: currentEnv,
  version: reportingReleaseVersion,
  sampleRate: isDevelopment || isTest ? 0 : 100,
  // Session replay not supported until we migrate off of browser-rum-slim
  sessionReplaySampleRate: 0,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  beforeSend: (event, context) => {
    // collect a RUM resource's response headers
    if (event.type === 'resource' && event.resource.type === 'fetch') {
      event.context = {
        ...event.context,
        responseHeaders: context.response.headers,
      };
    }
  },
});

export function reportError(error, context) {
  datadogRum.addError(error, context);
}

export const reportingMiddleware = () => next => async action => {
  try {
    return await next(action);
  } catch (error) {
    reportError(error, {
      action: action.type,
      reduxAction: action,
    });

    throw error;
  }
};
