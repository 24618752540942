import { processError, processAJAXError } from '@ftf/lib/errors';
import { reportError } from '@ftf/lib/reporting';

export function addAlert({ message, type, ...params }) {
  window.dispatchEvent(
    new CustomEvent('Alerts:add', { detail: { message, type, ...params } }),
  );
}

export function handleError(error, withReport = true) {
  addAlert({ message: processError(error), type: 'danger' });

  if (withReport) {
    reportError(error);
  }
}

export function handleInfo(message, params) {
  addAlert({ message, ...params });
}

export function handleSuccess(message, params) {
  addAlert({ message, type: 'success', ...params });
}

export function handleAJAXError(e, jqXHR) {
  addAlert({ message: processAJAXError(jqXHR), type: 'danger' });
}
