import axios from 'axios';
import { noop } from 'lodash';

const APICaller = {
  BASE_API_URL: '/api/',

  getCSRFToken() {
    return document.getElementsByTagName('meta')['csrf-token'].content;
  },

  call({ url, method, data, onUploadProgress = noop, multiPart = false }) {
    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': multiPart
        ? 'multipart/form-data'
        : 'application/vnd.api+json',
      Accept: multiPart ? 'multipart/form-data' : 'application/vnd.api+json',
      'X-CSRF-Token': this.getCSRFToken(),
    };

    return axios({
      baseURL: this.BASE_API_URL,
      credentials: 'same-origin',
      data,
      headers,
      method,
      onUploadProgress,
      url,
    }).catch(error => {
      if (error.response.status === 401) {
        window.location.reload();
      }

      throw error;
    });
  },

  get(url) {
    return this.call({ url, method: 'GET' });
  },

  post(url, data, onUploadProgress, multiPart) {
    return this.call({
      url,
      method: 'POST',
      data,
      onUploadProgress,
      multiPart,
    });
  },

  put(url, data, onUploadProgress, multiPart) {
    return this.call({
      url,
      method: 'PUT',
      data,
      onUploadProgress,
      multiPart,
    });
  },

  patch(url, data, onUploadProgress, multiPart) {
    return this.call({
      url,
      method: 'PATCH',
      data,
      onUploadProgress,
      multiPart,
    });
  },

  delete(url) {
    return this.call({ url, method: 'DELETE' });
  },

  all(...calls) {
    return axios.all(calls);
  },
};

export default APICaller;

// export the spread function from axios so that
// when importing this module and using the .all
// command from axios, you don't need to import
// axios again to call .spread
export { spread } from 'axios';
